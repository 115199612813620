<template>
  <div>
    <b-row>
      <b-col md="12">
        <div class="card card-custom gutter-b">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">
                Binance Book Ticker <span class="text-muted">Last Fetch : {{ new Date(this.$store.state.binance.lastFetch).toLocaleString("id-ID") }}</span>
              </h3>
            </div>
            <div class="card-toolbar">
              <button :disabled="binance.fetching" @click="refreshBookTicker()" class="btn btn-primary">
                <template v-if="!binance.fetching"> <b-icon-plus-circle /> Refresh </template>
                <template v-else> <b-spinner small></b-spinner> Memuat </template>
              </button>
            </div>
          </div>
          <div class="card-body">
            <b-form-group label="Pencarian" label-for="filter-input" label-cols-sm="2" label-align-sm="right" label-size="sm" class="mb-3">
              <b-input-group size="sm">
                <b-form-input id="filter-input" v-model="binance.filter" type="search" placeholder="Ketikkan untuk pencarian"></b-form-input>
                <b-input-group-append>
                  <b-button :disabled="!binance.filter" @click="binance.filter = ''">Clear</b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
            <!-- table filter -->
            <b-table
              striped
              hover
              :items="listBookTicker"
              :fields="binance.fields"
              :filter="binance.filter"
              :filter-included-fields="binance.filterOn"
              :current-page="binance.currentPage"
              :per-page="binance.perPage"
            >
              <template #cell(action)="data">
                <div class="text-right">
                  <b-button-group>
                    <b-button size="sm" variant="outline-primary" @click="formModalCoin(data.item.nama_coin)"> <b-icon-pencil /></b-button>
                    <b-button size="sm" variant="outline-primary" @click="hapusCoin(data.item.nama_coin)"> <b-icon-trash /></b-button>
                  </b-button-group>
                </div>
              </template>
            </b-table>
            <b-row>
              <b-col md="8"></b-col>
              <b-col md="4">
                <b-pagination v-model="binance.currentPage" :total-rows="binance.totalRows" :per-page="binance.perPage" align="fill" size="sm" class="my-0"></b-pagination>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>
    </b-row>
    <!-- modals -->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      binance: {
        fetching: false,
        totalRows: 1,
        currentPage: 1,
        perPage: 50,
        filter: null,
        filterOn: ["symbol"],
        // Note 'isActive' is left out and will not appear in the rendered table
        fields: [
          {
            key: "symbol",
            label: "Pair",
            sortable: true,
          },
          {
            key: "bidPrice",
            label: "Bid Price",
            sortable: false,
          },
          {
            key: "bidQty",
            label: "Bid Qty",
            sortable: false,
          },
          {
            key: "askPrice",
            label: "Ask Price",
            sortable: false,
          },
          {
            key: "askQty",
            label: "Ask Qty",
            sortable: false,
          },
        ],
        items: [],
      },
    };
  },
  computed: {
    ...mapGetters(["listBookTicker"]),
  },
  components: {},
  methods: {
    refreshBookTicker() {
      this.binance.fetching = true;
      this.$store.dispatch("updateBookTicker").then(response => {
        console.log("respon", response);
        if (response.status != "ok") {
          Swal.fire({
            title: "Gagal memuat book ticker!",
            text: response.message,
            icon: "error",
            heightAuto: false,
          });
        } else {
          this.$bvToast.toast("Book Ticker berhasil binance di update", {
            title: `Binance Book Ticker`,
            variant: "success",
            solid: true,
          });
        }
        this.binance.totalRows = this.listBookTicker.length;
        this.binance.fetching = false;
      });
    },
  },
  mounted() {
    this.binance.totalRows = this.listBookTicker.length;
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Market Watch" }, { title: "Market Data" }]);
  },
};
</script>
